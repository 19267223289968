import { dateField, lookupField, moneyField, percentField } from "./utils";

let bituchimResolver = {
  id: {
    display: "מזהה ביטוח",
    getValue(item) {
      return `${item.index}:${item.mispar_heshbon}`;
    }
  },
  mispar_heshbon: { display: "מספר חשבון", ltr: true },
  sug_mutzar: lookupField("סוג מוצר", {
    1: "ביטוח מנהלים",
    2: "קרן פנסיה",
    3: "קופת גמל",
    4: "קרן השתלמות",
    5: "ביטוח מנהלים",
    6: "ביטוח חיים",
    7: "ביטוח חיים",
    8: "ביטוח קולקטיב",
    9: "קופת גמל להשקעה"
  }),

  shem_yatzran: { display: "חברה מנהלת" },
  kod_mezahe_yatzran: { display: "ח.פ חברה מנהלת" },
  yatzran_logo: {
    display: "לוגו חברה מנהלת",
    getValue(item) {
      return item.kod_mezahe_yatzran;
    },
    fieldType: "logo"
  },
  taarich_tchilat_kisuy: dateField("תאריך תחילת כיסוי"),
  taarich_tom_kisuy: dateField("תאריך תום כיסוי"),
  active: lookupField("סטאטוס תוכנית", {
    0: "לא פעיל",
    1: "פעיל"
  }),

  tags: { display: "קטגוריות", type: "chips", multiple: true },

  sug_tochnit_o_cheshbon: {
    display: "מעמד בתוכנית",
    displayValues: [
      { value: 1, display: "שכיר" },
      { value: 2, display: "עצמאי" },
      { value: 3, display: "בעל שליטה" },
      { value: 4, display: "אחר" },
      { value: 5, display: "פרט" },
      { value: 6, display: "שכיר ועצמאי" }
    ]
  },

  index: { display: "מס מעקב" },
  maslul_index: { display: "אינדקס מסלול השקעה" },
  taarich_nechonut: dateField("תאריך נכונות מידע"),

  polisa_str: { display: "מספר מערכת" },

  sug_kisui_bituchi: { display: "כיסוי" },
  sug_kisui: lookupField("סוג כיסוי", {
    1: "כיסוי למקרה מוות",
    2: "נכות מקצועית",
    3: "מוות מתאונה",
    4: "נכות מתאונה",
    5: "אובדן כושר עבודה",
    5.99: "מטריה - אובדן כושר עבודה",
    6: "שחרור",
    7: "מחלות קשות",
    8: "תוכנית משולבת חיסכון",
    9: "מוות + אבדן כושר עבודה",
    10: "אחר",
    2.1: "פנסיית שארים",
    2.5: "פנסיית נכות"
  }),
  kod_mezahe_yatzran_and_sug_kisui: {
    display: "ח.פ חברה מנהלת עם סוג כיסוי",
    getValue(item) {
      return `${item.kod_mezahe_yatzran}:${item.sug_kisui}`;
    }
  },
  shem_mutzar: { display: "שם מוצר" },
  sachar_mevutach: moneyField("שכר מבוטח"),
  achoz_me_sachar: percentField("אחוז שכר מבוטח"),
  kisui_bituchi: moneyField("כיסוי ביטוחי"),
  alut_chodshei: moneyField("עלות חודשית"),
  total_premia_zfoya: moneyField("התפתחות עלות כוללת לתקופה"),
  sug_hafrashot: { display: "סוג הפרשות" },
  sug_kisui_etzel_yatzran: lookupField("סוג כיסוי אצל יצרן", {
    1: "ביטוח יסודי",
    2: "נספח (ריידר)"
  }),
  mevutah: {
    display: "מבוטח",
    getValue(item) {
      if (item.lakoach_mevutah == 0) {
        return item.mevutach_ids.join(", ");
      }
      return "לקוח";
    }
  },
  sug_mevutach: lookupField("סוג מבוטח", {
    1: "ראשי",
    2: "משני",
    3: "ילד",
    4: "הדדי",
    5: "אחר"
  }),
  ofen_tashlum: lookupField("אופן תשלום", {
    1: "סכום חד פעמי",
    2: "קצבה",
    3: "לשיעורין",
    4: "אחר"
  }),
  kalul_bamutzar_pensioni_yes_no: { display: "כלול במוצר פנסיוני" },
  kod_maslul_bituach: { display: "קוד מסלול ביטוח" },
  polisa_kidud_achid: { display: "קידוד אחיד" },
  nechut_mitpatahat: lookupField("פנסיית נכות מתפתחת", {
    1: "כן",
    2: "לא"
  }),
  kolel_prenzisa: lookupField("פרנציזה (נכות כפולה)", {
    1: "כן",
    2: "לא"
  }),
  nechut_vitor_gil_60_and_above: lookupField("ויתור פנסיית נכות מגיל 60", {
    1: "כן",
    2: "לא",
    3: "לא רלוונטי"
  })
};

export default bituchimResolver;
