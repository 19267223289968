import fieldStatus from "./field_status";
import rules from "@/utils/validations";

let fields = [
  {
    name: "change_status",
    category: "details",
    status: fieldStatus.nonrelevant
  },

  {
    name: "mispar_heshbon",
    display: "מספר חשבון",
    category: "details",
    class: "ltr"
  },

  {
    name: "sug_mutzar",
    display: "סוג מוצר",
    category: "details",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: "ביטוח מנהלים (1)" },
      { value: 2, text: "קרן פנסיה (2)" },
      { value: 3, text: "קופת גמל (3)" },
      { value: 4, text: "קרן השתלמות (4)" },
      { value: 5, text: "ביטוח מנהלים (5)" },
      { value: 6, text: "ביטוח חיים (6)" },
      { value: 7, text: "ביטוח חיים (7)" },
      { value: 8, text: "ביטוח קולקטיב (8)" },
      { value: 9, text: "קופת גמל להשקעה (9)" }
    ]
  },

  {
    name: "shem_yatzran",
    display: "חברה מנהלת",
    category: "details",
    computed: true
  },

  {
    name: "kod_mezahe_yatzran",
    display: "ח.פ חברה מנהלת",
    category: "details",
    computed: true
  },

  {
    name: "mispar_siduri_shel_hahalvaa",
    display: "מספר הלוואה",
    category: "details",
    class: "ltr"
  },

  // {
  //   name: "active",
  //   display: "פעיל/לא פעיל",
  //   category: "details",
  //   type: "select",
  //   values: [
  //     { value: 0, text: "לא פעיל (0)" },
  //     { value: 1, text: "פעיל (1)" }
  //   ]
  // },

  {
    name: "tags",
    display: "קטגוריות",
    category: "details",
    type: "combobox",
    multiple: true,
    chips: true,
    values: ["השלמה", "בן/בת זוג", "לברור"]
  },

  // {
  //   name: "sug_tochnit_o_cheshbon",
  //   display: "סטאטוס עצמאי/שכיר/בעל שליטה",
  //   category: "details",
  //   forceNumeric: true,
  //   type: "select",
  //   values: [
  //     { value: 1, text: "שכיר (1)" },
  //     { value: 2, text: "עצמאי (2)" },
  //     { value: 3, text: "בעל שליטה (3)" },
  //     { value: 4, text: "אחר (4)" },
  //     { value: 5, text: "פרט (5)" },
  //     { value: 6, text: "שכיר ועצמאי (6)" }
  //   ]
  // },

  {
    name: "general-details-title",
    display: "פרטי מערכת",
    category: "details",
    title: true
  },
  { name: "file", display: "קובץ XML", category: "details", computed: true },
  { name: "index", display: "מס מעקב", category: "details", computed: true },
  {
    name: "maslul_index",
    display: "אינדקס מסלול השקעה",
    category: "details",
    computed: true
  },

  {
    name: "taarich_nechonut",
    display: "תאריך נכונות מידע",
    category: "details"
  },

  {
    name: "polisa_str",
    category: "details",
    display: "מספר מערכת",
    computed: true,
    class: "ltr"
  },

  {
    name: "str_sug_mutzar",
    display: "סוג מוצר",
    category: "details",
    computed: true,
    status: fieldStatus.nonrelevant
  },

  {
    name: "main_product",
    category: "details",
    computed: true,
    status: fieldStatus.nonrelevant
  },

  {
    name: "product",
    display: "מוצר",
    category: "details",
    computed: true,
    status: fieldStatus.nonrelevant
  },

  {
    name: "schum_halvaa",
    display: "סכום הלוואה",
    category: "halvaa",
    type: "number",
    prefix: "₪",
    rules: [rules.positive]
  },

  {
    name: "yitrat_halvaa",
    display: "יתרה לתשלום",
    category: "halvaa",
    type: "number",
    prefix: "₪",
    rules: [rules.positive]
  },

  {
    name: "taarich_kabalat_halvaa",
    display: "תאריך קבלת הלוואה",
    category: "halvaa"
  },

  {
    name: "taarich_siyum_halvaa",
    display: "תאריך סיום הלוואה",
    category: "halvaa"
  },

  {
    name: "yesh_halvaa_bamutzar",
    display: "כלול במוצר פנסיוני",
    category: "halvaa",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: " כן (1)" },
      { value: 2, text: "לא (2)" }
    ]
  },

  {
    name: "ramat_halvaa",
    display: "הלוואה מתוך",
    category: "halvaa",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: " מוצר (1)" },
      { value: 2, text: "כל המוצרים של הלקוח (2)" }
    ]
  },

  {
    name: "tkufat_halvaa",
    display: "תקופת הלוואה (חודשים)",
    category: "halvaa",
    type: "number",
    rules: [rules.positive]
  },

  {
    name: "ribit",
    display: "ריבית",
    category: "halvaa",
    type: "number",
    suffix: "%",
    rules: [rules.positive, rules.range(0, 100)]
  },

  {
    name: "sug_ribit",
    display: "סוג ריבית",
    category: "halvaa",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: " קבועה (1)" },
      { value: 2, text: "משתנה (2)" }
    ]
  },

  {
    name: "sug_hatznmada",
    display: "סוג הצמדה",
    category: "halvaa",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: " לא צמוד (1)" },
      { value: 2, text: "צמוד למדד חודשי (2)" },
      { value: 3, text: "צמוד למדד חצי שנתי (3)" },
      { value: 4, text: "אחר (4)" }
    ]
  },

  {
    name: "tadirut_hechzer_halvaa",
    display: "תדירות החזר הלוואה",
    category: "halvaa",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: " חודשי (1)" },
      { value: 2, text: "רבעוני (2)" },
      { value: 3, text: "חצי שנתי (3)" },
      { value: 4, text: "שנתי (4)" },
      { value: 5, text: "אחר (5)" }
    ]
  },

  {
    name: "sug_hechzer",
    display: "סוג החזר",
    category: "halvaa",
    type: "select",
    forceNumeric: true,
    values: [
      { value: 1, text: " שפיצר (1)" },
      { value: 2, text: "ריבית שוטפת (2)" }
    ]
  },

  {
    name: "schum_hechzer_tkufati",
    display: "החזר תקופתי ב-₪",
    category: "halvaa",
    type: "number",
    prefix: "₪",
    rules: [rules.positive]
  },

  {
    name: "fixes",
    display: "תיקונים",
    category: "details",
    computed: true,
    status: fieldStatus.nonrelevant
  }
];

let categories = [
  { name: "details", display: "פרטים כללים", fields: [] },
  { name: "halvaa", display: "פרטי הלוואה", fields: [] }
];

//index fields
let fieldByName = {};
fields.forEach((field) => (fieldByName[field.name] = field));

//index categories
let categoryByName = {};
categories.forEach((category) => (categoryByName[category.name] = category));

//populate categories fields
fields.forEach((field) => categoryByName[field.category].fields.push(field));

export default {
  fields,
  fieldByName,
  categories,
  categoryByName
};
